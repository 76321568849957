import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../Inputs/InputField";
import SelectField from "../../Inputs/SelectField";
import { Button, Divider } from "@mui/material";
import "./projectBoard.css";
import { PROJECT_ROWS, projectColumns, projectColumnsFull } from "../data";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
const ProjectBoard = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setValue: setFormValue,
    getValues,
  } = useForm({
    defaultValues: {
      projectName: "Test Project",
      about: "This is a test project for testing purposes.",
      methodology: "ACR",
      methodologyDescription:
        "Methodology for the Quantification, Monitoring, Reporting and Verification of Greenhouse Gas Emissions Reductions and Removals.",
      status: "active",
      type: "Test Type",
      location: "Test Location",
      registry: "Test Registry",
      quantity: "100",
      validationVerificationBody: "Test Body",
      lastUpdated: "2024-01-01",
      numberOfOffsetsCreated: 10,
      datePlugged: "2024-01-01",
      dateCompleted: "2024-01-01",
      creditingPeriod: "2024-12-31",
      wells: [
        { uwi: "TEST-UWI-001", lat: "45.0", long: "-75.0" },
        { uwi: "TEST-UWI-002", lat: "45.5", long: "-75.5" },
      ],
      files: [],
      media: [],
    },
  });

  const [methodologyDescription, setMethodologyDescription] = useState("");
  const [projects, setProjects] = useState(null);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "ACR") {
      setMethodologyDescription(
        "Methodology for the Quantification, Monitoring, Reporting and Verification of Greenhouse Gas Emissions Reductions and Removals from Plugging Orphan Oil and Gas Wells in the U.S. and Canada, V1.0, Errata and Clarifications June 13, 2024 and September 13, 2024."
      );
    } else {
      setMethodologyDescription(""); // Clear the description for other selections
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useEffect(() => {
    console.log(selectedFiles);
  }, [selectedFiles]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    console.log(Array.from(files));
    if (files.length > 0) {
      setSelectedFiles(Array.from(files));
      setValue("files", Array.from(files)); // Update react-hook-form with selected files
    }
    console.log(Array.from(files));
  };

  const handleMediaChange = (event) => {
    const media = event.target.files;
    console.log(event);
    if (media.length > 0) {
      setSelectedMedia(Array.from(media));
      setValue("media", Array.from(media));
    }
  };

  const uploadToS3 = async (file, url) => {
    try {
      const upload = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      console.log(upload);
    } catch (error) {
      console.error("Error uploading file:", file.name, error);
      throw new Error(`Failed to upload ${file.name}`);
    }
  };

  const onSubmit = async (data) => {
    try {
      const user = await fetchAuthSession();
      const token = user.tokens.idToken;

      // Prepare file names and file types for both files and media
      const fileDetails = data.files.map((file) => ({
        fileName: file.name,
        fileType: file.type,
      }));

      const mediaDetails = data.media.map((media) => ({
        fileName: media.name,
        fileType: media.type,
      }));

      // Remove the selected projects from the form data for submission
      const {
        address,
        lat,
        long,
        selectedProjects,
        ...dataWithoutSelectedProjects
      } = data;

      // Prepare the payload for the backend with only file names and types
      const jsonPayload = {
        ...dataWithoutSelectedProjects,
        location: {
          address: data.address,
          lat: parseFloat(data.lat),
          long: parseFloat(data.long),
        },
        files: fileDetails, // Pass the file name and type only
        media: mediaDetails, // Pass the media file name and type only
      };

      if (isEditing) {
        // Update project
        const presignedResponse = await axios.patch(
          `${process.env.REACT_APP_BACKEND_API}/project/${selectedProjectId}`,
          jsonPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const { filePaths, mediaFilePaths } = presignedResponse.data;
        const fileUploads = selectedFiles.map((file, index) =>
          uploadToS3(file, filePaths[index])
        );
        const mediaUploads = selectedMedia.map((media, index) =>
          uploadToS3(media, mediaFilePaths[index])
        );
        await Promise.all([...fileUploads, ...mediaUploads]);
        alert("Project updated successfully!");
        setIsEditing(false);
      } else {
        // Create new project
        const presignResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/project`,
          jsonPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(presignResponse.data);
        const { filePaths, mediaFilePaths } = presignResponse.data;
        const fileUploads = selectedFiles.map((file, index) =>
          uploadToS3(file, filePaths[index])
        );
        const mediaUploads = selectedMedia.map((media, index) =>
          uploadToS3(media, mediaFilePaths[index])
        );
        await Promise.all([...fileUploads, ...mediaUploads]);
        alert("Project created successfully!");
      }

      fetchProjects(); // Refresh the project list after submission
    } catch (error) {
      console.error(
        isEditing ? "Error updating project:" : "Error creating project:",
        error
      );
      alert(
        isEditing
          ? "Failed to update project. Please try again."
          : "Failed to create project. Please try again."
      );
    }
  };

  const [projectsLoading, setProjectsLoading] = useState(false);
  const fetchProjects = async () => {
    setProjectsLoading(true);
    try {
      const user = await fetchAuthSession();

      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API + "/project",
        {
          headers: {
            Authorization: `Bearer ${user.tokens.idToken}`,
          },
        }
      );
      setProjects(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setProjectsLoading(false);
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  const [wells, setWells] = useState([
    { id: Date.now(), uwi: "UWI-001", lat: "45.0", long: "-75.0" },
  ]);

  const handleAddWell = () => {
    setWells([...wells, { id: Date.now(), uwi: "", lat: "", long: "" }]);
  };

  const [selectedProjectRows, setSelectedProjectRows] = useState([]);
  useEffect(() => {
    setValue("selectedProjects", selectedProjectRows);
  }, [selectedProjectRows, setValue]);

  const handleDeleteProjects = async () => {
    if (selectedProjectRows.length === 0) {
      alert("Please select at least one project to delete.");
      return;
    }

    console.log(selectedProjectRows);

    try {
      const user = await fetchAuthSession();
      const token = user.tokens.idToken;

      await Promise.all(
        selectedProjectRows.map(async (project) => {
          try {
            await axios.delete(
              `${process.env.REACT_APP_BACKEND_API}/project/${project}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            console.log(`Deleted project ${project}`);
          } catch (error) {
            console.error(`Failed to delete project ${project}:`, error);
          }
        })
      );

      // Refresh the user list after deletion
      fetchProjects();
      setSelectedProjectRows([]); // Clear selection
    } catch (err) {
      console.error("Error deleting projects:", err);
    }
  };
  const [selectedProjectId, setSelectedProjectId] = useState();
  const fetchProjectById = async (projectId) => {
    try {
      const user = await fetchAuthSession();
      const token = user.tokens.idToken;

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/project/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const projectData = response.data;
      console.log(projectData);
      // Populate form fields
      Object.keys(projectData).forEach((key) => {
        setValue(key, projectData[key]);
      });

      if (projectData.location) {
        setValue("address", projectData.location.address || "");
        setValue("lat", projectData.location.lat || "");
        setValue("long", projectData.location.long || "");
      }
      console.log(getValues());
      if (projectData.methodology) {
        setValue("methodologySelect", projectData.methodology);
      }
      setWells(projectData.wells);
      setMethodologyDescription(projectData.methodologyDescription);

      const [projectDocsResult, projectMediaResult] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_BACKEND_API}/project-data/${projectId}`,
          {
            headers: { Authorization: `Bearer ${user.tokens.idToken}` },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_BACKEND_API}/project-data/${projectId}`,
          {
            headers: { Authorization: `Bearer ${user.tokens.idToken}` },
            params: { folderType: "media" },
          }
        ),
      ]);
      setSelectedFiles(Array.from(projectDocsResult.data.files));
      setValue("files", Array.from(projectDocsResult.data));
      setSelectedMedia(Array.from(projectMediaResult.data.files));
      setValue("media", Array.from(projectMediaResult.data.files));

      setSelectedProjectId(projectId);
      setIsEditing(true); // Enable edit mode
    } catch (error) {
      console.error(`Failed to fetch project ${projectId}:`, error);
    }
  };

  useEffect(() => {
    console.log(selectedFiles);
  }, [selectedFiles]);

  const handleEditUser = () => {
    if (selectedProjectRows.length !== 1) return;
    const selectedProject = selectedProjectRows[0];
    setIsEditing(true);
    fetchProjectById(selectedProject);
  };
  return (
    <div className="userBoard__container">
      <form
        className="user__form"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div>
          <h2>Create Project</h2>
          <h3 className="auth__subheader">Add a project to the database</h3>
        </div>
        <InputField
          id="projectName"
          label="Project Name"
          type="text"
          register={register}
          validationRules={{
            required: "Project Name is required",
          }}
          errors={errors}
          errorMessageKey="projectName"
        />
        <InputField
          id="about"
          label="About"
          register={register}
          validationRules={{
            required: "About section is required",
          }}
          errors={errors}
          errorMessageKey="about"
          rows={5}
        />
        <SelectField
          id="methodologySelect"
          label="Methodology"
          options={[{ value: "ACR", label: "ACR" }]}
          register={register}
          validationRules={{
            required: "Methodology is required",
          }}
          onChange={handleDropdownChange}
          errors={errors}
          errorMessageKey="methodology"
        />
        <InputField
          id="methodology"
          label="Methodology Description"
          value={methodologyDescription}
          onChange={(e) => setMethodologyDescription(e.target.value)}
          register={register}
          validationRules={{
            required: "Methodology Description is required",
          }}
          errors={errors}
          errorMessageKey="methodologyDescription"
          rows={5}
        />
        <SelectField
          id="status"
          label="Status"
          options={[
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
            { value: "completed", label: "Completed" },
          ]}
          register={register}
          validationRules={{
            required: "Status is required",
          }}
          errors={errors}
          errorMessageKey="status"
        />
        <InputField
          id="type"
          label="Type"
          type="text"
          register={register}
          validationRules={{
            required: "Type is required",
          }}
          errors={errors}
          errorMessageKey="type"
        />
        <InputField
          id="address"
          label="Address"
          type="text"
          register={register}
          validationRules={{
            required: "Address is required",
          }}
          errors={errors}
          errorMessageKey="address"
        />
        <InputField
          id="lat"
          label="Latitude"
          type="text"
          register={register}
          validationRules={{
            required: "Latitude is required",
          }}
          errors={errors}
          errorMessageKey="lat"
        />
        <InputField
          id="long"
          label="Longitude"
          type="text"
          register={register}
          validationRules={{
            required: "Longitude is required",
          }}
          errors={errors}
          errorMessageKey="longitude"
        />
        <InputField
          id="registry"
          label="Registry"
          type="text"
          register={register}
          validationRules={{
            required: "Registry is required",
          }}
          errors={errors}
          errorMessageKey="registry"
        />
        <InputField
          id="quantity"
          label="Quantity"
          type="text"
          register={register}
          validationRules={{
            required: "Quantity is required",
          }}
          errors={errors}
          errorMessageKey="quantity"
        />
        <InputField
          id="validationVerificationBody"
          label="Validation Verification Body"
          type="text"
          register={register}
          validationRules={{
            required: "Validation Verification Body is required",
          }}
          errors={errors}
          errorMessageKey="validationVerificationBody"
        />
        <InputField
          id="lastUpdated"
          label="Last Updated"
          type="date"
          register={register}
          validationRules={{
            required: "Last Updated date is required",
          }}
          errors={errors}
          errorMessageKey="lastUpdated"
        />
        <InputField
          id="numberOfOffsetsCreated"
          label="Number of Offsets Created"
          type="number"
          register={register}
          validationRules={{
            required: "Number of Offsets Created is required",
            min: { value: 0, message: "Number must be 0 or greater" },
          }}
          errors={errors}
          errorMessageKey="numberOfOffsetsCreated"
        />
        <InputField
          id="datePlugged"
          label="Date Plugged"
          type="date"
          register={register}
          validationRules={{
            required: "Date Plugged is required",
          }}
          errors={errors}
          errorMessageKey="datePlugged"
        />
        <InputField
          id="dateCompleted"
          label="Date Completed"
          type="date"
          register={register}
          validationRules={{
            required: "Date Completed is required",
          }}
          errors={errors}
          errorMessageKey="dateCompleted"
        />
        <InputField
          id="creditingPeriod"
          label="Crediting Period"
          type="date"
          register={register}
          validationRules={{
            required: "Crediting Period is required",
          }}
          errors={errors}
          errorMessageKey="creditingPeriod"
        />
        <h3>Add Wells</h3>
        <div className="wells__container">
          {wells.map((well, index) => (
            <div key={well.id} className="well__inputs">
              <InputField
                id={`wells[${index}].uwi`}
                label={`Well ${index + 1} UWI`}
                type="text"
                register={register}
                validationRules={{
                  required: "UWI is required",
                }}
                errors={errors}
                errorMessageKey={`wells[${index}].uwi`}
              />
              <InputField
                id={`wells[${index}].lat`}
                label={`Well ${index + 1} Latitude`}
                type="number"
                register={register}
                validationRules={{
                  required: "Latitude is required",
                  min: {
                    value: -90,
                    message: "Latitude must be between -90 and 90",
                  },
                  max: {
                    value: 90,
                    message: "Latitude must be between -90 and 90",
                  },
                }}
                errors={errors}
                errorMessageKey={`wells[${index}].lat`}
              />
              <InputField
                id={`wells[${index}].long`}
                label={`Well ${index + 1} Longitude`}
                type="number"
                register={register}
                validationRules={{
                  required: "Longitude is required",
                  min: {
                    value: -180,
                    message: "Longitude must be between -180 and 180",
                  },
                  max: {
                    value: 180,
                    message: "Longitude must be between -180 and 180",
                  },
                }}
                errors={errors}
                errorMessageKey={`wells[${index}].long`}
              />
            </div>
          ))}
          <Button
            type="button"
            variant="outlined"
            onClick={handleAddWell}
            sx={{
              height: "2.5rem",
              width: "7rem",
              borderRadius: "5px",
              alignSelf: "flex-end",
              marginBottom: "1.8rem",
            }}
            size="small"
          >
            Add Well
          </Button>
        </div>
        {/* File Upload */}
        <div className="auth__input-container">
          <label className="auth__input-label">Upload Files</label>
          <Button
            variant="contained"
            component="label"
            color={errors.files ? "error" : "primary"}
            className="file__button"
          >
            Choose Files
            <input
              type="file"
              accept=".pdf,.doc,.docx,.txt"
              hidden
              multiple // Allow multiple file selection
              {...register("files")}
              onChange={handleFileChange}
            />
          </Button>
          {selectedFiles.length > 0 && (
            <table className="file-table">
              <thead>
                <tr>
                  <th>File Name</th>
                </tr>
              </thead>
              <tbody>
                {selectedFiles.map((file, index) => (
                  <tr key={index}>
                    <td>{file.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="error__container">
            {errors.files && <span>{errors.files.message}</span>}
          </div>
        </div>

        {/* Media Upload */}
        <div className="auth__input-container">
          <label className="auth__input-label">Upload Images & Videos</label>

          <Button
            variant="contained"
            component="label"
            color={errors.media ? "error" : "primary"}
            className="file__button"
          >
            Choose Files
            <input
              type="file"
              accept="image/*,video/*"
              hidden
              multiple // Allow multiple file selection
              {...register("media")}
              onChange={handleMediaChange}
            />
          </Button>
          <div className="error__container">
            {errors.mediaUpload && <span>{errors.mediaUpload.message}</span>}
          </div>
          {selectedMedia.length > 0 && (
            <table className="media-table">
              <thead>
                <tr>
                  <th>Image Name</th>
                </tr>
              </thead>
              <tbody>
                {selectedMedia.map((media, index) => (
                  <tr key={index}>
                    <td>{media.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="project__submit-button">
          <button className="auth__button" type="submit">
            {isEditing ? "Update Project" : "Create Project"}
          </button>
        </div>
      </form>

      <Divider sx={{ marginTop: "2.5rem" }} />

      <div className="userGrid__container">
        <div className="dataGrid__container-user">
          <div className="dataGrid__header">
            <div>
              <h2>Projects</h2>
              <h3 className="auth__subheader">
                View, delete, and edit projects
              </h3>
            </div>

            <div className="dataGrid__buttonGroup">
              <button
                className="action__button"
                onClick={handleEditUser}
                disabled={selectedProjectRows.length !== 1} // Only enable when exactly 1 user is selected
              >
                <EditIcon
                  sx={{
                    color: "#999999",
                    fontSize: "1.3rem",
                    marginRight: "5px",
                  }}
                />
                <span>Edit</span>
              </button>
              <button className="action__button" onClick={handleDeleteProjects}>
                <DeleteOutlineIcon
                  sx={{
                    color: "#999999",
                    fontSize: "1.3rem",
                    marginRight: "5px",
                  }}
                />
                <span>Delete</span>
              </button>
            </div>
          </div>

          <DataGrid
            sx={{
              border: "none",
              boxShadow: "none",
              width: "100%",
            }}
            getRowId={(row) => row.projectId}
            className="custom-grid"
            rows={projects}
            columns={projectColumnsFull}
            pageSize={6}
            rowsPerPageOptions={[6]}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectedProjectRows(newSelection);
              console.log("Selected projects:", selectedProjectRows); // Debugging log
            }}
            loading={projectsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectBoard;
