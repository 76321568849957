import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationCodeInput from "./VerificationForm";
import "./verification.css";
import { confirmSignUp } from "aws-amplify/auth";
import axios from "axios";
const Verification = () => {
  const { setValue, watch, handleSubmit, setError, clearErrors } = useForm({
    defaultValues: {
      verificationCode: Array(6).fill(""), // Default to a 6-character verification code
    },
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const [verificationStage, setVerificationStage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const verificationCode = watch("verificationCode");
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();

  const dummyCode = "123456"; // Replace this with the actual dummy verification code

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      if (verificationCode[index] === "" && index > 0) {
        setActiveIndex(index - 1);
      } else {
        setValue(`verificationCode.${index}`, "", {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData
      .getData("text/plain")
      .slice(0, verificationCode.length);
    const updatedCode = [...verificationCode];

    for (let i = 0; i < pastedData.length; i++) {
      if (activeIndex + i < updatedCode.length) {
        updatedCode[activeIndex + i] = pastedData[i];
      }
    }

    updatedCode.forEach((value, index) =>
      setValue(`verificationCode.${index}`, value, {
        shouldValidate: true,
        shouldDirty: true,
      })
    );
    setActiveIndex(activeIndex + pastedData.length);
  };

  const handleChange = (newValue, index) => {
    setValue(`verificationCode.${index}`, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });

    clearErrors("verificationCode");

    if (newValue && index < verificationCode.length - 1) {
      setActiveIndex(index + 1);
    }
  };

  const onSubmit = async (data) => {
    const code = data.verificationCode.join(""); // Combine the array into a single string
    try {
      const { nextStep: confirmSignUpNextStep } = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });

      if (confirmSignUpNextStep.signUpStep === "DONE") {
        setVerificationStage(2); // Move to the next stage
        clearErrors("verificationCode");
        setErrorMessage("");
      }

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_API}/user/${email}`, // Use email in the URL
        {
          status: "Registered",
          accessToken: null,
        }
      );
    } catch (err) {
      setError("verificationCode", {
        type: "manual",
        message: "Invalid verification code. Please try again.",
      });
      setErrorMessage("Invalid verification code. Please try again.");
    }
  };

  const handleLoginClick = () => {
    if (verificationStage === 2) {
      navigate("/login");
    }
  };

  return (
    <div className="verification__container">
      <header className="verification-header">
        {verificationStage === 1 ? (
          <>
            <span className="verification-header__title">
              Verify your CrbonScope account
            </span>
            <span className="verification-header__subtitle">
              Thank you for registering with us! We have sent you a verification
              code to confirm your account at <b>{email}</b>.
            </span>
          </>
        ) : (
          <>
            <span className="verification-header__title">
              Account Verified!
            </span>
            <span className="verification-header__subtitle">
              <b>{email}</b> has been successfully verified. Please click
              continue to login.
            </span>
            <span>Welcome aboard! 🚀</span>
          </>
        )}
      </header>
      <form
        className="verification__form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div className="verification-form__wrapper">
          <VerificationCodeInput
            length={6} // Number of input fields
            values={verificationCode}
            setValue={setValue}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            errorMessage={errorMessage}
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
        </div>

        <span className={`verification-error ${errorMessage ? "active" : ""}`}>
          {errorMessage}
        </span>

        <div className="verification__btn">
          {verificationStage === 1 ? (
            <button className="auth__button" type="submit">
              Verify
            </button>
          ) : (
            <button
              className="auth__button"
              type="button"
              onClick={handleLoginClick}
            >
              Login
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Verification;
