import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { projectColumns, PROJECT_ROWS, userColumns } from "../data";
import "./userBoard.css";
import InputField from "../../Inputs/InputField";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";
import BaseModal from "../../Modal/BaseModal";
import EditUser from "./EditUser";

export const modalContainerStyles = {
  borderRadius: "10px",
};

const UserBoard = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [users, setUsers] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const fetchUsers = async () => {
    setUserLoading(true);
    try {
      const user = await fetchAuthSession();
      console.log(user);
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API + "/user",
        {
          headers: {
            Authorization: `Bearer ${user.tokens.idToken}`,
          },
        }
      );
      setUsers(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setUserLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const [selectedProjectRows, setSelectedProjectRows] = useState([]);
  const [inviteOnCreation, setInviteOnCreation] = useState("yes");
  const onSubmit = async (data) => {
    try {
      const user = await fetchAuthSession();

      const createdDate = new Date()
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-");
      const { email, companyName } = data;

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_API + "/user",
        {
          email,
          name: companyName,
          status: inviteOnCreation === "yes" ? "Invited" : "Not Invited",
          created: createdDate,
        },
        {
          headers: {
            Authorization: `Bearer ${user.tokens.idToken}`,
          },
        }
      );
      alert(`User created successfully: ${email}`);

      await Promise.all(
        selectedProjectRows.map(async (projectId) => {
          try {
            const projectResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_API}/user-project`,
              {
                email,
                projectID: projectId,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.tokens.idToken}`,
                },
              }
            );
            console.log(
              `Project ${projectId} assigned to user ${email}`,
              projectResponse.data
            );
            alert(`Project ${projectId} successfully assigned to ${email}`);
          } catch (projectError) {
            console.error(
              `Failed to assign project ${projectId} to user ${email}:`,
              projectError
            );
            alert(`Failed to assign project ${projectId} to ${email}`);
          }
        })
      );

      fetchUsers();
    } catch (err) {
      console.log(err);
      alert("Failed to create user. Please try again.");
    }
  };

  const [projectsLoading, setProjectsLoading] = useState(false);
  const fetchProjects = async () => {
    setProjectsLoading(true);
    try {
      const user = await fetchAuthSession();

      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API + "/project",
        {
          headers: {
            Authorization: `Bearer ${user.tokens.idToken}`,
          },
        }
      );
      setProjects(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setProjectsLoading(false);
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);
  const [projects, setProjects] = useState(null);
  const onProjectRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      selectedProjectRows.find((row) => row.id === id)
    );
    console.log(selectedRowsData);
  };

  // Sync selectedRows with form value when it changes
  useEffect(() => {
    setValue("selectedProjects", selectedProjectRows); // Sync selected rows with react-hook-form's state
  }, [selectedProjectRows, setValue]);

  const [selectedUserRows, setSelectedUserRows] = useState([]);

  const handleDeleteUsers = async () => {
    if (selectedUserRows.length === 0) {
      alert("Please select at least one user to delete.");
      return;
    }

    try {
      const user = await fetchAuthSession();
      const token = user.tokens.idToken;

      await Promise.all(
        selectedUserRows.map(async (user) => {
          try {
            await axios.delete(
              `${process.env.REACT_APP_BACKEND_API}/user/${user.email}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            console.log(`Deleted user ${user.email}`);
            alert(`Deleted user: ${user.email}`);
          } catch (error) {
            console.error(`Failed to delete user ${user.email}:`, error);
            alert(`Failed to delete user: ${user.email}`);
          }
        })
      );

      fetchUsers();
      setSelectedUserRows([]);
    } catch (err) {
      console.error("Error deleting users:", err);
      alert("Error deleting users. Please try again.");
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleEditUser = async () => {
    if (selectedUserRows.length !== 1) {
      alert("Please select exactly one user to edit.");
      return;
    }
    const selectedUser = selectedUserRows[0];

    try {
      const user = await fetchAuthSession();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/user-project/${selectedUser.email}`,
        {
          headers: { Authorization: `Bearer ${user.tokens.idToken}` },
        }
      );

      console.log(selectedUser);
      console.log(response);

      setModalData({
        ...selectedUser,
        selectedProjects: response.data,
      });
      setModalOpen(true);
    } catch (err) {
      console.error("Error fetching user projects:", err);
      alert("Failed to load user details for editing.");
    }
  };

  return (
    <div className="userBoard__container">
      <form
        className="user__form"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div className="userBoard__content">
          {/* Inputs Section */}
          <div className="userBoard__inputsContainer">
            <div>
              <h2>Create User</h2>
              <h3 className="auth__subheader">Add a user to the database</h3>
            </div>
            <InputField
              id="companyName"
              label="Company Name"
              type="text"
              register={register}
              validationRules={{
                required: "Company Name is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s&\-.,'()]+$/,
                  message: "Invalid Company Name",
                },
              }}
              errors={errors}
              errorMessageKey="companyName"
            />
            <InputField
              id="email"
              label="Email"
              type="text"
              register={register}
              validationRules={{
                required: "Email Name is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              }}
              errors={errors}
              errorMessageKey="email"
            />

            <label className="auth__input-label">Invite on Creation?</label>
            <RadioGroup
              value={inviteOnCreation}
              onChange={(event) => setInviteOnCreation(event.target.value)}
              row
              required
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </div>

          {/* Grid Section */}
          <div className="dataGrid__container">
            <DataGrid
              sx={{ marginLeft: "auto", width: "70%" }}
              getRowId={(row) => row.projectId}
              className="custom-grid"
              rows={projects}
              columns={projectColumns}
              checkboxSelection
              onRowSelectionModelChange={(newSelection) => {
                setSelectedProjectRows(newSelection);
                console.log("Selected projects:", selectedProjectRows); // Debugging log
              }}
              loading={projectsLoading}
            />
          </div>
        </div>
        {/* Button Section */}
        <div className="auth__button-container">
          <button className="auth__button" type="submit">
            Create
          </button>
        </div>
      </form>
      <Divider sx={{ marginTop: "2.5rem" }} />
      <div className="userGrid__container">
        <div className="dataGrid__container-user">
          <div className="dataGrid__header">
            <div>
              <h2>Users</h2>
              <h3 className="auth__subheader">View, delete and edit users</h3>
            </div>

            <div className="dataGrid__buttonGroup">
              <button className="action__button">
                <MailOutlineIcon
                  sx={{
                    color: "#999999",
                    fontSize: "1.3rem",
                    marginRight: "5px",
                  }}
                />
                <span>Invite</span>
              </button>
              <button
                className="action__button"
                onClick={handleEditUser}
                disabled={selectedUserRows.length !== 1} // Only enable when exactly 1 user is selected
              >
                <EditIcon
                  sx={{
                    color: "#999999",
                    fontSize: "1.3rem",
                    marginRight: "5px",
                  }}
                />
                <span>Edit</span>
              </button>

              <button className="action__button" onClick={handleDeleteUsers}>
                <DeleteOutlineIcon
                  sx={{
                    color: "#999999",
                    fontSize: "1.3rem",
                    marginRight: "5px",
                  }}
                />
                <span>Delete</span>
              </button>
            </div>
          </div>
          <DataGrid
            getRowId={(row) => row.userId}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selected = users.filter((row) =>
                selectedIDs.has(row.userId)
              );
              setSelectedUserRows(selected);
              console.log(selected);
            }}
            selectionModel={selectedUserRows}
            rows={users}
            columns={userColumns}
            checkboxSelection
            className="custom-grid-user"
            loading={userLoading}
          />
        </div>
      </div>
      <BaseModal
        open={modalOpen}
        title={"Edit User"}
        subTitle={"Edit user data and associated projects"}
        modalContainerStyles={modalContainerStyles}
        handleClose={handleModalClose}
        content={
          <EditUser
            projects={projects}
            userData={modalData}
            handleClose={handleModalClose}
          />
        }
        maxWidth={"md"}
      />
    </div>
  );
};

export default UserBoard;
