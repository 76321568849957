import { Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
export const projectColumns = [
  {
    field: "projectId",
    headerName: "Project ID",
    headerClassName: "dataGrid_columnHeader",
    flex: 1, // Changed to a number
  },
  {
    field: "projectName",
    headerName: "Project Name",
    headerClassName: "dataGrid_columnHeader",
    flex: 3, // Adjusted for better proportion
  },
];

export const PROJECT_ROWS = [
  {
    id: 1,
    projectId: "ACR978",
    project: "Crbon Labs Plugging of Orphaned Wells V1",
  },
  {
    id: 2,
    projectId: "17X9",
    project: "Epsilon Energy Holdings - Field Advancement",
  },
  {
    id: 3,
    projectId: "14Z3",
    project: "Quantum Fuel Technologies - Reservoir Development",
  },
  {
    id: 4,
    projectId: "22R7",
    project: "DeltaCore Petroleum - Extraction Systems",
  },
  {
    id: 5,
    projectId: "20H6",
    project: "AlphaCore PetroTech - Reservoir Engineering",
  },
  {
    id: 6,
    projectId: "02R3",
    project: "Stratus Energy Solutions - Resource Management",
  },
];

export const projectColumnsFull = [
  {
    field: "projectId",
    headerName: "Project ID",
    headerClassName: "dataGrid_columnHeader",
    flex: 1,
  },
  {
    field: "projectName",
    headerName: "Project Name",
    headerClassName: "dataGrid_columnHeader",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "type",
    headerName: "Type",
    headerClassName: "dataGrid_columnHeader",
    flex: 2,
  },
  {
    field: "registry",
    headerName: "Registry",
    headerClassName: "dataGrid_columnHeader",
    flex: 2,
  },
  {
    field: "quantity",
    headerName: "Quantity (tCO2e)",
    headerClassName: "dataGrid_columnHeader",
    flex: 2,
  },
  {
    field: "location",
    headerName: "Location",
    headerClassName: "dataGrid_columnHeader",
    flex: 2,
  },
  {
    field: "dateCompleted",
    headerName: "Date Completed",
    headerClassName: "dataGrid_columnHeader",
    flex: 2,
  },
  {
    field: "lastUpdated",
    headerName: "Last Updated",
    headerClassName: "dataGrid_columnHeader",
    flex: 2,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "dataGrid_columnHeader",
    flex: 1,
  },
];

export const PROJECT_ROWS_FULL = [
  {
    id: 1,
    projectId: "ACR978",
    project: "Crbon Labs Plugging of Orphaned Wells V1",
    type: "Wellbore Geothermal",
    registry: "American Carbon Registry",
    quantity: "10,436 tCO2e",
    location: "Calgary, AB",
    dateCompleted: "Dec 15, 2025",
    lastUpdated: "Jan 20, 2026",
    status: "Completed",
  },
  {
    id: 2,
    projectId: "17X9",
    project: "Epsilon Energy Holdings - Field Advancement",
    type: "Carbon Intensity",
    registry: "B.C Carbon Offset Registry",
    quantity: "8,750 tCO2e",
    location: "Edmonton, AB",
    dateCompleted: "Nov 10, 2025",
    lastUpdated: "Jan 05, 2026",
    status: "Active",
  },
  {
    id: 3,
    projectId: "14Z3",
    project: "Quantum Fuel Technologies - Reservoir Development",
    type: "Direct Air Capture",
    registry: "Alberta Energy Regulator",
    quantity: "5,320 tCO2e",
    location: "Red Deer, AB",
    dateCompleted: "Oct 01, 2025",
    lastUpdated: "Dec 22, 2025",
    status: "On-hold",
  },
  {
    id: 4,
    projectId: "22R7",
    project: "DeltaCore Petroleum - Extraction Systems",
    type: "Battery Storage",
    registry: "BCarbon",
    quantity: "12,100 tCO2e",
    location: "Lethbridge, AB",
    dateCompleted: "Sep 25, 2025",
    lastUpdated: "Dec 10, 2025",
    status: "Completed",
  },
  {
    id: 5,
    projectId: "20H6",
    project: "AlphaCore PetroTech - Reservoir Engineering",
    type: "Micro Solar",
    registry: "American Carbon Registry",
    quantity: "6,890 tCO2e",
    location: "Medicine Hat, AB",
    dateCompleted: "Aug 14, 2025",
    lastUpdated: "Nov 18, 2025",
    status: "Active",
  },
  {
    id: 6,
    projectId: "02R3",
    project: "Stratus Energy Solutions - Resource Management",
    type: "Carbon Offsets",
    registry: "B.C Carbon Offset Registry",
    quantity: "9,500 tCO2e",
    location: "Fort McMurray, AB",
    dateCompleted: "Jul 30, 2025",
    lastUpdated: "Oct 05, 2025",
    status: "On-hold",
  },
];

export const userColumns = [
  {
    field: "userId",
    headerName: "User ID",
    headerClassName: "dataGrid_columnHeader",
    flex: 1, // Changed to a number
  },
  {
    field: "name",
    headerName: "Company Name",
    headerClassName: "dataGrid_columnHeader",
    flex: 3, // Adjusted for better proportion
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "dataGrid_columnHeader",
    flex: 2, // Adjusted for better proportion
  },
  {
    field: "created",
    headerName: "Created",
    headerClassName: "dataGrid_columnHeader",
    flex: 2, // Adjusted for better proportion
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "dataGrid_columnHeader",
    flex: 1, // Adjusted for better proportion
    renderCell: (params) => {
      const getColor = (status) => {
        switch (status) {
          case "Registered":
            return "success";
          case "Not Invited":
            return "warning";
          case "Invited":
            return "info";
          default:
            return "default"; // Handle unknown cases gracefully
        }
      };
      return (
        <Chip
          icon={<CircleIcon sx={{ fontSize: "0.8rem", opacity: "0.8" }} />}
          label={params.value}
          variant="outlined"
          color={getColor(params.value)}
        />
      );
    },
  },
];

export const USER_ROWS = [
  {
    id: 1,
    userID: "U1001",
    companyName: "Crbon Labs",
    email: "contact@crbonlabs.com",
    created: "2025-01-01",
    status: "Registered",
  },
  {
    id: 2,
    userID: "U1002",
    companyName: "Epsilon Energy",
    email: "info@epsilonenergy.com",
    created: "2025-01-02",
    status: "Invite Sent",
  },
  {
    id: 3,
    userID: "U1003",
    companyName: "Quantum Fuel Tech",
    email: "support@quantumfuel.com",
    created: "2025-01-03",
    status: "Registered",
  },
  {
    id: 4,
    userID: "U1004",
    companyName: "DeltaCore Petroleum",
    email: "admin@deltacore.com",
    created: "2025-01-04",
    status: "Not Invited",
  },
  {
    id: 5,
    userID: "U1005",
    companyName: "AlphaCore PetroTech",
    email: "hello@alphacore.com",
    created: "2025-01-05",
    status: "Registered",
  },
  {
    id: 6,
    userID: "U1006",
    companyName: "Stratus Energy Solutions",
    email: "contact@stratusenergy.com",
    created: "2025-01-06",
    status: "Registered",
  },
  {
    id: 7,
    userID: "U1007",
    companyName: "EcoLogic Resources",
    email: "team@ecologic.com",
    created: "2025-01-07",
    status: "Invite Sent",
  },
  {
    id: 8,
    userID: "U1008",
    companyName: "GreenCore Technologies",
    email: "info@greencore.com",
    created: "2025-01-08",
    status: "Not Invited",
  },
  {
    id: 9,
    userID: "U1009",
    companyName: "BlueSky Energy",
    email: "support@blueskyenergy.com",
    created: "2025-01-09",
    status: "Registered",
  },
  {
    id: 10,
    userID: "U1010",
    companyName: "NextGen Fuels",
    email: "admin@nextgenfuels.com",
    created: "2025-01-10",
    status: "Registered",
  },
];
