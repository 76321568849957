import { Element } from "react-scroll";
import React, { useEffect, useState, useRef } from "react";
import { useIsVisible } from "../../Hooks/IsVisible";
import "./stats.css";
import ReactPlayer from "react-player";
import crbonLabsIcon from "../../images/SVGsforCrbon/CRbonLogo.svg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReactGA from "react-ga4";
const Count = ({ data, isVisible }) => {
  const [count, setCount] = useState("0");

  const intervalRef = useRef();

  useEffect(() => {
    let start = 0;
    const end = parseInt(data.number.substring(0, 3));
    if (!isVisible || start === end) return;

    let totalMilSecDur = parseInt(data.duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      start += 1;
      setCount(String(start) + data.number.substring(3));
      if (start === end) clearInterval(intervalRef.current);
    }, incrementTime);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [data, isVisible]);

  const showMillion = data.label === "Tonnes GHG\nemissions eliminated";
  const showThousand = data.label === "Wells\ndecomissioned";
  const showPercent = data.label === "Sites converted \n to renewable energy";

  return (
    <div className="stats-image">
      <div className="count-box">
        <div className="count-number">
          {parseInt(count).toLocaleString()}
          {showMillion && <span className="count-unit">M</span>}
          {showThousand && <span className="count-unit">K</span>}
          {showPercent && <span className="count-unit">%</span>}
        </div>
      </div>
      <div className="count-text">{data.label}</div>
    </div>
  );
};

const Stats = () => {
  const visibleRef = useRef();
  const isVisible = useIsVisible(visibleRef);

  const visibleRef2 = useRef();
  const isVisible2 = useIsVisible(visibleRef2);

  const [animationPlayed, setAnimationPlayed] = useState(false);

  const handleAnimationEnd = () => {
    setAnimationPlayed(true);
  };
  const countData = {
    counts: [
      {
        id: "001",
        label: "Wells\ndecomissioned",
        number: "10",
        duration: "2",
      },
      {
        id: "002",
        label: "Sites converted \n to renewable energy",
        number: "50",
        duration: "2",
      },
      {
        id: "003",
        label: "Tonnes GHG\nemissions eliminated",
        number: "100",
        duration: "2",
      },
    ],
  };

  const [counts, setCounts] = useState([]);

  useEffect(() => {
    setCounts(countData.counts);
  }, []);

  return (
    <Element
      id="stats"
      name="Stats"
      className="stats-container section-padding"
    >
      <div className="stats-page wrapper">
        <div className="stats-header-container">
          <h1 className="heading-primary">
            Experience the power of our impact firsthand.
          </h1>
        </div>
        <div className="stats-bottom-container">
          <div
            className={`stats-text-container ${isVisible2 ? "fadeRight" : ""} ${
              animationPlayed ? "animation-done" : ""
            }`}
            ref={visibleRef2}
            onAnimationEnd={handleAnimationEnd}
          >
            <div className="stats-text">
              <strong>Our goal</strong> is to decommission 10,000 wells. This
              translates into eliminating 100 million tonnes of GHG emissions,
              and rewilding 100 square kilometers (40 square miles) of land.
              <br />
              <br />
              We’ll also remove 2,500 kilometers (1,700 miles) of private road
              and convert 50% of our well sites to renewable energy.
            </div>
          </div>
          <div className="stats-image-container">
            <ReactPlayer
              url={process.env.REACT_APP_S3_VIDEOS + "/statsVideo.mp4"}
              width="100%"
              height="100%"
              controls={true}
              onStart={() =>
                ReactGA.event({
                  category: "Video",
                  action: "Video Started",
                  label: "Stats Video",
                })
              }
              onPause={() =>
                ReactGA.event({
                  category: "Video",
                  action: "Video Paused",
                  label: "Stats Video",
                })
              }
              onEnded={() =>
                ReactGA.event({
                  category: "Video",
                  action: "Video Completed",
                  label: "Stats Video",
                })
              }
              onProgress={({ playedSeconds }) => {
                if (playedSeconds >= 11) {
                  ReactGA.event({
                    category: "Video",
                    action: "Watched 11 Seconds",
                    label: "Stats Video",
                  });
                }
              }}
              light={
                <img
                  src={crbonLabsIcon}
                  alt="Video Thumbnail"
                  className="custom-light-image"
                />
              }
              playIcon={
                <PlayArrowIcon
                  style={{
                    fontSize: "6rem",
                    color: "white",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    position: "absolute",
                    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                    filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))",
                  }}
                />
              }
            />
          </div>
        </div>
        <div className="stats-num-bar">
          <div className="heading-primary heading-primary-white">Our Goals</div>
          <div className="stats-images-container" ref={visibleRef}>
            {counts.map((count) => (
              <Count key={count.id} data={count} isVisible={isVisible} />
            ))}
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Stats;
