import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./login.css";
import { useNavigate } from "react-router-dom";
import InputField from "../Inputs/InputField";
import { signIn, fetchAuthSession } from "aws-amplify/auth";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useMediaQuery } from "@mui/material";
import ReactGA from "react-ga4";
export const Login = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width:768px)");
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const user = await fetchAuthSession();
        if (user.userSub !== undefined) {
          navigate("/"); // Redirect to home if already logged in
        }
      } catch (err) {
        // User is not authenticated, allow them to stay on login page
      } finally {
        setLoading(false); // Auth check is complete, set loading to false
      }
    };

    checkAuthStatus();
  }, [navigate]);

  const handleScrollToGradient = () => {
    document
      .querySelector(".login__gradient")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const onSubmit = async (data) => {
    ReactGA.event({
      category: "LOGIN_BUTTON", // Event category (should match what you defined in GA)
      action: "LOGIN_BUTTON",
      label: "LOGIN_BUTTON",
      value: 0,
      // Event action
    });
    if (data.invis) {
      // If the invis field is filled, it's likely a bot submission, so ignore it
      return;
    }
    try {
      const { email, loginPassword } = data;
      await signIn({ username: email, password: loginPassword });
      // Redirect to the main page after successful login
      setIsLoggedIn(true);
      navigate("/admin"); // Or whatever route you want to redirect to
    } catch (err) {
      // Check for specific AWS error code for incorrect login credentials

      // Set the error message for the password field
      setError("loginPassword", {
        type: "manual",
        message: "Incorrect email or password.",
      });
    }
  };
  const handleNavigateContact = () => {
    navigate(`/`);
    setTimeout(() => {
      document
        .getElementById("contact")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 500);
    ReactGA.event({
      category: "LOGIN_CONTACT_US", // Event category (should match what you defined in GA)
      action: "LOGIN_CONTACT_US",
      label: "LOGIN_CONTACT",
      value: 0,
      // Event action
    });
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="login__container">
      <div className="login__gradient">
        <div className="gradient__content">
          <h1 className="gradient__title">
            Explore Your Carbon Impact with Crbon Labs
          </h1>
          <p className="gradient__subtitle">
            At Crbon Labs, we’re revolutionizing carbon offset solutions by
            sealing abandoned and orphaned oil and gas wells—turning
            environmental liabilities into a force for good. Each offset you
            purchase directly supports our mission to reduce methane emissions
            and combat climate change. Once logged in, gain exclusive access to
            detailed project data for the offsets you’ve invested in, empowering
            you with transparency and insight into your positive impact.
          </p>
          <p className="gradient__subtitle-small">
            💡 Not a member yet? Let’s partner to offset your carbon footprint!
          </p>
          <p
            className="gradient__subtitle-small"
            style={{ marginBottom: "1rem" }}
          >
            Click below to connect with our team and discover Crbon Labs
            projects that help you reach your goals.
          </p>
          <button className="contact__button" onClick={handleNavigateContact}>
            Contact Us
          </button>
        </div>
      </div>
      <div className="auth__container">
        <form
          className="auth__form"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <h1 className="auth__header">Welcome Back!</h1>
          <h3 className="auth__subheader">Please sign in to continue</h3>

          <input
            type="text"
            style={{ position: "absolute", left: "-9999px" }}
            {...register("invis")}
            tabIndex="-1"
            autoComplete="off"
          />

          <InputField
            id="email"
            label="Email"
            type="email"
            register={register}
            validationRules={{
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Invalid email address",
              },
            }}
            errors={errors}
            errorMessageKey="email"
          />

          <InputField
            id="loginPassword"
            label="Password"
            type="password"
            register={register}
            validationRules={{
              required: "Password is required",
            }}
            errors={errors}
            errorMessageKey="loginPassword"
          />

          <button className="auth__button" type="submit">
            Login
          </button>
          <a style={{ alignSelf: "end" }} href="/forgot-password">
            Forgot Password?
          </a>
        </form>
        {isMobile && (
          <div className="arrow__icon" onClick={handleScrollToGradient}>
            <KeyboardDoubleArrowDownIcon fontSize="large" />
          </div>
        )}
      </div>
    </div>
  );
};
