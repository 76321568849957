import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import InputField from "../Inputs/InputField";
import "./register.css";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const verifyUserIsApproved = async () => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("access_token");

    // Redirect immediately if no access token is present
    if (!accessToken) {
      navigate("/");
      return;
    }

    console.log(accessToken);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/userAccess/${accessToken}`
      );
      console.log(response.data.status);
      if (response.data?.message === "User not found") {
        console.error("User not found, redirecting...");
        navigate("/");
      } else if (typeof response.data === "object") {
        console.log("User approved:", response.data);
        setIsLoading(false);
      } else {
        console.error("Unexpected response format:", response.data);
        navigate("/");
      }
    } catch (err) {
      console.error("User is not approved or there was an error:", err);
      navigate("/");
    }
  };

  useEffect(() => {
    verifyUserIsApproved();
  }, []); // Re-run when URL changes

  const onSubmit = async (data) => {
    try {
      if (data.invis) {
        // If the invis field is filled, it's likely a bot submission, so ignore it
        return;
      }
      const { email, password, companyName } = data;
      console.log(data);

      const signUpResponse = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            email: email, // Default
            name: companyName, // Custom attribute
          },
        },
      });

      console.log("Sign-up successful:", signUpResponse);
      navigate(`/verification?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.error("Error signing up:", error);
      alert(error.message || "An error occurred during sign-up");
    }
  };
  //comment

  if (isLoading) {
    return null;
  }

  return (
    <div className="register__container">
      <form
        className="register__form"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <h1 className="auth__header">Create Account</h1>
        <h3 className="auth__subheader">Please sign up to continue</h3>
        <div style={{ position: "absolute", left: "-9999px" }}>
          <input
            id="middleName"
            label="Middle Name"
            type="text"
            {...register("invis")}
            tabIndex="-1"
            autoComplete="off"
          />
        </div>

        <InputField
          id="companyName"
          label="Company Name"
          type="text"
          register={register}
          validationRules={{
            required: "Company Name is required",
            pattern: {
              value: /^[a-zA-Z0-9\s&\-.,'()]+$/,
              message: "Invalid Company Name",
            },
          }}
          errors={errors}
          errorMessageKey="companyName"
        />

        <InputField
          id="email"
          label="Email"
          type="email"
          register={register}
          validationRules={{
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Invalid email address",
            },
          }}
          errors={errors}
          errorMessageKey="email"
        />

        <InputField
          id="password"
          label="Password"
          type="password"
          register={register}
          validationRules={{
            required: "Password is required",
          }}
          errors={errors}
          errorMessageKey="password"
        />
        <button className="auth__button" type="submit">
          Register
        </button>
        <div>
          <span style={{ marginRight: "0.5rem" }}>
            Already have an account?
          </span>
          <a style={{ alignSelf: "end" }} href="/login">
            Sign In
          </a>
        </div>
      </form>
    </div>
  );
};

export default Register;
