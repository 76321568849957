import { fetchAuthSession } from "@aws-amplify/auth";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./projectDetail.css";
import { Alert, capitalize, CircularProgress, Divider } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import Map, {
  Marker,
  NavigationControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import protectedRoute from "../../protectedRoute";

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
  tablet: { breakpoint: { max: 1024, min: 768 }, items: 3 },
  mobile: { breakpoint: { max: 768, min: 0 }, items: 2 },
};

const ProjectDetail = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState([]);

  const [projectImages, setProjectImages] = useState([]);
  const mapRef = useRef();

  const [files, setFiles] = useState([]);

  const fetchProjectData = async () => {
    try {
      const user = await fetchAuthSession();

      // First API call to get project details
      const projectResult = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/project/${projectId}`,
        {
          headers: { Authorization: `Bearer ${user.tokens.idToken}` },
        }
      );

      // Next two API calls that depend on projectName
      const [projectDataResult, projectImagesResult] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_BACKEND_API}/project-data/${projectId}`,
          {
            headers: { Authorization: `Bearer ${user.tokens.idToken}` },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_BACKEND_API}/project-data/${projectId}`,
          {
            headers: { Authorization: `Bearer ${user.tokens.idToken}` },
            params: { folderType: "media" },
          }
        ),
      ]);

      setProjectImages(projectImagesResult.data.files || []);
      setSelectedImage(projectImagesResult.data.files[0]?.url);

      setFiles(projectDataResult.data.files || []);
      console.log(projectResult);
      setProject(projectResult.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, []);

  const formatProjectData = (data) => [
    { label: "Type", value: data.type },
    { label: "Location", value: data.location.address },
    { label: "Registry", value: data.registry },
    { label: "Quantity", value: data.quantity },
    {
      label: "Validation/Verification Body",
      value: data.validationVerificationBody,
    },
    { label: "Date Completed", value: data.dateCompleted },
    { label: "Number of Offsets Created", value: data.numberOfOffsetsCreated },
    {
      label: "Project Wells",
      value: data.wells?.map((well) => well.uwi).join(", ") || "N/A",
    },
    { label: "Date Plugged", value: data.datePlugged },
    { label: "Status", value: capitalize(data.status) },
    { label: "Crediting Period", value: data.creditingPeriod },
  ];

  const formattedProjectData = useMemo(() => {
    if (!project) return [];
    return formatProjectData(project);
  }, [project]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleDownloadSelectedFiles = async () => {
    if (!selectedFiles.length > 0) {
      setIsAlertOpen(true);
      setTimeout(() => {
        setIsAlertOpen(false);
      }, 2000);
    }
    try {
      for (const fileUrl of selectedFiles) {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${fileUrl}`);
        }
        const blob = await response.blob(); // Fetch file and convert to Blob
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob); // Create a URL for the Blob

        const fileName =
          decodeURIComponent(fileUrl.split("/").pop().split("?")[0]) ||
          "download";
        link.download = fileName; // Set the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes >= 1_073_741_824) {
      return `${(sizeInBytes / 1_073_741_824).toFixed(2)} GB`;
    } else if (sizeInBytes >= 1_048_576) {
      return `${(sizeInBytes / 1_048_576).toFixed(2)} MB`;
    } else if (sizeInBytes >= 1_024) {
      return `${(sizeInBytes / 1_024).toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  };

  const fileColumns = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 3,
      headerClassName: "dataGrid_columnHeader",
      renderCell: (params) => (
        <a
          href={params.row.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          {params.row.fileName}
        </a>
      ),
    },
    {
      field: "size",
      headerName: "Size",
      flex: 1,
      headerClassName: "dataGrid_columnHeader",
    },
  ];

  if (isLoading) {
    return (
      <div className="loading__cicle">
        <CircularProgress style={{ color: "rgba(140, 28, 86, 1)" }} />
      </div>
    );
  }

  return (
    <div className="projectDetail_container">
      <a href={"/projects"} className="projectDetail_back">
        <KeyboardBackspaceIcon />
        <span>Projects</span>
      </a>
      <h1>{project.projectName}</h1>
      <div className="project__content">
        <div className="image__section">
          <div className="selected__image">
            <img src={selectedImage} alt="Selected" />
          </div>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            infinite={false}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            itemClass="carousel-item"
            dotListClass="custom-dot-list"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            className="image__selection"
          >
            {projectImages.map((image, index) => (
              <img
                key={index}
                src={image.url}
                alt={image.name}
                className="imageThumbnail" // Adjusted class to control gaps
                loading="lazy"
                onClick={() => setSelectedImage(image.url)}
              />
            ))}
          </Carousel>
        </div>
        <div className="project-text__container">
          <div className="description__container">
            <div className="description__content">
              <h2>About</h2>
              <p>{project.about}</p>
            </div>
            <div className="description__content">
              <h2>Methodology</h2>
              <p>{project.methodology}</p>
            </div>
          </div>
          <Divider style={{ marginTop: "3rem" }} />
          <div className="project__fieldsContainer">
            {formattedProjectData.map((field, index) => (
              <span key={index} className="project__field">
                <strong style={{ marginBottom: "3px" }}>{field.label}</strong>{" "}
                {field.value}
              </span>
            ))}
          </div>
        </div>
        <Divider style={{ marginTop: "3rem" }} />
        <div className="location__section">
          <h2>Location</h2>
          <p>{project.location.address}</p>
          <p style={{ marginBottom: "1rem" }}>
            {project.location.lat}, {project.location.long}
          </p>
          <Map
            ref={mapRef}
            initialViewState={{
              latitude: project.location.lat,
              longitude: project.location.long,
              zoom: 4,
            }}
            style={{ width: "100%", height: "100%" }}
            mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX}
          >
            <GeolocateControl position="bottom-right" />
            <NavigationControl position="bottom-right" showCompass={false} />
            <ScaleControl />
            {project.wells?.map((well) => (
              <Marker
                key={well.uwi}
                latitude={parseFloat(well.lat)}
                longitude={parseFloat(well.long)}
              >
                <RoomIcon sx={{ color: "red", fontSize: "1.5rem" }} />
              </Marker>
            ))}
          </Map>
        </div>
        <Divider style={{ marginTop: "9rem" }} />
        <div className="file__section">
          <h2>Documents</h2>
          <div className="document__btns">
            {" "}
            <button
              className="action__button"
              onClick={handleDownloadSelectedFiles}
            >
              <DownloadIcon
                sx={{
                  color: "#999999",
                  fontSize: "1.3rem",
                  marginRight: "5px",
                }}
              />
              <div className="download__text">Download</div>
            </button>
          </div>

          <DataGrid
            sx={{
              border: "none",
              boxShadow: "none",
              width: "100%",
            }}
            rows={files.map((file, index) => ({
              id: index,
              fileName: file.name,
              url: file.url,
              size: formatFileSize(file.size) || "Unknown", // Add size if your backend includes it
            }))}
            className="custom-grid"
            columns={fileColumns}
            pageSize={6}
            rowsPerPageOptions={[6]}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              const selectedUrls = newSelection.map(
                (selectedId) => files[selectedId]?.url
              );
              setSelectedFiles(selectedUrls);
            }}
          />
        </div>
      </div>
      {isAlertOpen && (
        <Alert className="alert" variant="outlined" severity="info">
          Select one or more files to download.
        </Alert>
      )}
    </div>
  );
};

export default protectedRoute(ProjectDetail);
