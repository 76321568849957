import React, { useEffect, useState } from "react";
import "./projects.css";
import { Element } from "react-scroll";
import ReactGA from "react-ga4";
import projectImage1 from "../../images/projectImage1.jpg";
import projectImage2 from "../../images/projectImage2.jpg";
import projectImage3 from "../../images/projectImage3.jpg";
import ProjectPopup from "./ProjectPopup";
import canadianFlag from "../../images/canadianFlag.jpg";
const projectsDB = [
  {
    projectName: "ACR978 Crbon Labs Plugging of Orphaned Wells V1",
    methodology: "American Carbon Registry",
    description:
      "Crbon Labs Plugging Orphaned Oil and Gas Wells V1 project ACR978 is the first project of its kind in Canada to measure quantify and plug an orphaned gas well for the purposes of eliminating leaking methane. By plugging this orphan well, Crbon Labs avoided the release of 85,145 tCO2e into the atmosphere.",
    location: {
      address: "Wainwright #61, Alberta, Canada",
      lat: 53.093945,
      long: -111.093261,
    },
    images: [projectImage1, projectImage2, projectImage3],
    status: "Validated & Verified",
  },
  {
    projectName: "ACR1093 Crbon Labs Plugging of Alberta Oprhaned Wells V2",
    methodology: "American Carbon Registry",
    description:
      "Crbon Labs Plugging Alberta Orphaned Oil and Gas Wells V2 project ACR1093 is Crbon Labs' second project to measure quantify and plug an orphaned gas well for the purposes of eliminating leaking methane. This project is located in the MD of Red Dear, Alberta, Canada. By plugging this leaking well, Crbon Labs expects to avoid the release of approximately 100,000tCO2e into the atmosphere.",
    location: {
      address: "Red Dear, Alberta, Canada",
      lat: 52.075911,
      long: -114.41905,
    },
    images: [projectImage2, projectImage1, projectImage3],
    status: "Listed",
  },
  {
    projectName: "Crbon Labs Methane Capture and Remediation Project V1",
    methodology: "BCarbon Registry",
    description:
      "Crbon labs Methane Capture and Remediation Project V1 developed under the BCarbon Registry Methane Capture and Remediation protocol is the first of its kind in Canada. In collaboration with a large Canadian Oil and Gas producer located in Alberta, Crbon Labs avoided the release of 39,893 tCO2e. This project is located in the remote Northern Alberta community of Rainbow Lake.",
    location: {
      address: "Rainbow Lake, Alberta, Canada",
      lat: 58.69176,
      long: -118.65809,
    },
    images: [projectImage3, projectImage1, projectImage2],
    status: "Validation Phase",
  },
];

const Projects = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (isPopupOpen) {
      html.classList.add("lock-scroll");
    } else {
      html.classList.remove("lock-scroll");
    }
    return () => {
      html.classList.remove("lock-scroll");
    };
  }, [isPopupOpen]);

  const openPopup = (project) => {
    setSelectedProject(project);
    setPopupOpen(true);
    document.body.style = "overflow-y: hidden;";
    console.log(project);
    if (project.projectName)
      ReactGA.event({
        category: "PROJECT_CLICK", // Event category (should match what you defined in GA)
        action: "PROJECT_CLICK",
        label: project.projectName,
        value: project.projectName + "click",
        // Event action
      });
  };

  const closePopup = () => {
    setPopupOpen(false);
    document.body.className = "";
  };

  const getMapboxImageUrl = (lat, long) => {
    return `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/pin-l+ff0000(${long},${lat})/${long},${lat},8,0/600x400?access_token=${process.env.REACT_APP_MAPBOX}`;
  };

  return (
    <Element
      id="projects"
      name="projects"
      className="founders-container section-padding"
    >
      <div className="profile-header-container">
        <div className="heading-primary">Our Current Projects</div>
        <div className="profile-text-header">
          Crbon Labs High Fidelity Crbon™ Opportunites Under Development
        </div>
      </div>
      <div className="profile-section-wrapper" style={{ marginTop: "5rem" }}>
        <div className="projects-wrapper founders-wrapper">
          {projectsDB.map((project, index) => (
            <div
              className={`project-card`}
              key={index}
              onClick={() => openPopup(project)}
            >
              <img className="project-flag" src={canadianFlag} />
              <div className="project-banner">{project.status}</div>
              <div className="project-img-container">
                <img
                  src={getMapboxImageUrl(
                    project.location.lat,
                    project.location.long
                  )}
                  alt={`Map of ${project.projectName}`}
                />
              </div>
              <div className="project-name-container">
                <div className="profile-title-name">{project.projectName}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedProject && (
        <ProjectPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          project={selectedProject}
        />
      )}
    </Element>
  );
};

export default Projects;
