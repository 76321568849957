import React, { useEffect, useRef } from "react";
import "./crbonScope.css";
import { startTypingEffect } from "./typewriter";
import { textBlurbs } from "./textBlurbs";
import { Element, Link } from "react-scroll";
import ReactGA from "react-ga4";
const CrbonScope = () => {
  const typewriterRef = useRef(null);
  // Start the typing effect onMount
  useEffect(() => {
    if (typewriterRef.current) {
      startTypingEffect(typewriterRef.current, textBlurbs);
    }
  }, []);

  return (
    <Element
      id="crbonscope"
      name="crbonscope"
      className="founders-container section-padding crbonScope-header"
    >
      <div className="profile-header-container">
        <div className="heading-primary">CRBONSCOPE</div>
        <div className="profile-text-header">
          Introducing Crbon Scope, a new way of looking at existing oil and gas
          facilities as assets in the energy transition.
        </div>
      </div>
      <div className="landing__main__text">
        <span className="text-primary-crbonScope">
          We are developing technology that will help us identify oil and gas
          wells{" "}
        </span>
        <span className="text-white">
          <span className="typewriter-text" ref={typewriterRef}></span>
        </span>
      </div>

      <div className="landing__button__container">
        <Link
          className="submit-btn"
          to="contact"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => {
            ReactGA.event({
              category: "CRBONSCOPE_CONTACT_US", // Event category (should match what you defined in GA)
              action: "CRBONSCOPE_CONTACT_US",
              label: "CRBONSCOPE_CONTACT",
              value: 0,
              // Event action
            });
          }}
        >
          Contact Us
        </Link>
      </div>
    </Element>
  );
};

export default CrbonScope;
