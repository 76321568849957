import { Element } from "react-scroll";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useIsVisible } from "../../Hooks/IsVisible";

import "./contact.css";

import ReactGA from "react-ga4";

const Contact = () => {
  const form = useRef();

  const visibleref = useRef();
  const isVisible = useIsVisible(visibleref);
  const visibleref2 = useRef();
  const isVisible2 = useIsVisible(visibleref2);

  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [animationPlayed2, setAnimationPlayed2] = useState(false);

  const handleAnimationEnd = (identifier, setAnimationPlayed) => {
    if (!setAnimationPlayed) return; // Ensure the setAnimationPlayed function is provided
    setAnimationPlayed(true);
  };

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "EMAIL_SENT", // Event category (should match what you defined in GA)
      action: "EMAIL_SENT",
    });
    setIsSending(true); // Set sending state to true
    emailjs
      .sendForm(
        "service_d2x7r8t",
        "template_wqv2hqp",
        e.target,
        "AcIQxSSupIT7tIKiY"
      )
      .then(
        (result) => {
          setIsSent(true); // Set sent state to true
          setIsSending(false); // Reset sending state after a delay (if desired)
          document.getElementById("ContactForm").reset();
          setTimeout(() => {
            setIsSent(false);
          }, 2000); // Reset sent state after 2 seconds (adjust as needed)
        },
        (error) => {
          setIsSending(false); // Reset sending state on error
        }
      );
  };

  return (
    <Element
      id="contact"
      name="contact"
      className="bottom-section section-padding"
    >
      <div name="contactform" className="contact-container wrapper">
        <div
          className={`contact-text-container ${isVisible2 ? "fadeRight" : ""} ${
            animationPlayed2 ? "animation-done" : ""
          }`}
          ref={visibleref2}
          onAnimationEnd={() => handleAnimationEnd("0", setAnimationPlayed2)}
        >
          <div className="contact-header-container">
            <div className="heading-primary">Let's talk.</div>
          </div>
          <div className="contact-heading-text">
            Are you looking to create value for your company while making a
            direct, immediate, and permanent impact on GHG emissions? Let's
            start a conversation about how{" "}
            <span className="trademark-span">High Fidelity Crbon™</span> offsets
            can help you achieve your sustainability goals and make a meaningful
            difference.
            <br></br>
            <br></br>
            Contact us today to learn more about our sustainable solutions and
            how we can work together to create a better future for all.
          </div>
        </div>
        <div
          className={`contact-content-container ${isVisible ? "fadeUp" : ""} ${
            animationPlayed ? "animation-done" : ""
          }`}
          ref={visibleref}
          onAnimationEnd={() => handleAnimationEnd("1", setAnimationPlayed)}
        >
          <h3 className="test">CONTACT US</h3>
          <form id="ContactForm" ref={form} onSubmit={sendEmail}>
            <label className="contact-textarea-field">Name</label>
            <input
              className="contact-input-field"
              type="text"
              name="user_name"
              required
            />
            <label className="contact-textarea-field">Email</label>
            <input
              className="contact-input-field"
              type="email"
              name="user_email"
              required
            />
            <label className="contact-textarea-field">Message</label>
            <textarea
              className="contact-input-field message-field"
              name="message"
            />
            <div className="submit-container">
              <input
                type="submit"
                value={isSending ? "Sending..." : isSent ? "Sent!" : "Send"}
                disabled={isSending}
                className={isSent ? "submit-btn sent" : "submit-btn"}
              />
            </div>
          </form>
        </div>
      </div>
    </Element>
  );
};

export default Contact;
