import React, { useEffect, useState } from "react";
import { getCurrentUser } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import Footer from "./components/Footer/Footer";

const protectedRoute = (Comp) => (props) => {
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  async function checkAuthState() {
    try {
      await getCurrentUser(); // Check if user is authenticated
      setLoading(false); // User is authenticated, stop loading
    } catch (err) {
      navigate("/login"); // Redirect if user is not authenticated
    }
  }

  useEffect(() => {
    checkAuthState();
  }, []);

  if (loading) {
    // Render a loading indicator or nothing while checking authentication
    return <div>Loading...</div>;
  }

  return (
    <>
      <Comp {...props} />
      <Footer />
    </>
  );
};

export default protectedRoute;
