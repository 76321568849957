import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Mission from "./components/Mission/Mission";
import Stats from "./components/Stats/stats";
import Profiles from "./components/Profiles/Profiles";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import Quality from "./components/Quality/quality";
import MarketingProjects from "./components/MarketingProjects/Projects";
import CrbonScope from "./components/CrbonScope/CrbonScope";

import ReactGA from "react-ga4";
import { Login } from "./components/Login/Login";
import Register from "./components/Register/Register";
import Verification from "./components/Verification/Verification";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Admin from "./components/Admin/Admin";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import ProjectOverview from "./components/ProjectOverview/ProjectOverview";
import ProjectDetail from "./components/ProjectDetail/ProjectDetail";

// Initialize Google Analytics
ReactGA.initialize("G-6S6HE328VX");

Amplify.configure(awsconfig);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check for ID token in localStorage on mount
    const checkLocalStorage = () => {
      for (const key of Object.keys(localStorage)) {
        if (key.includes(".idToken")) {
          const token = localStorage.getItem(key);
          return !!token; // Return true if token exists
        }
      }
      return false; // Return false if no token found
    };

    const tokenExists = checkLocalStorage();
    setIsLoggedIn(tokenExists);
  }, []); // Runs once on component mount

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <Router>
      <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routes>
        {/* Main Marketing Page */}
        <Route
          path="/"
          element={
            <div className="site-wrapper">
              <Home />
              <Mission />
              <Quality />
              <Stats />
              <MarketingProjects />
              <CrbonScope />
              <Profiles />
              <Contact />
              <Footer />
            </div>
          }
        />
        <Route
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />
        <Route path="/register" element={<Register />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/admin" element={<Admin />} />
        <Route path="/projects" element={<ProjectOverview />} />
        <Route path="/projects/:projectId" element={<ProjectDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
