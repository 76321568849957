import React, { useEffect, useState } from "react";
import protectedRoute from "../../protectedRoute";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PROJECT_ROWS_FULL, projectColumnsFull } from "../Admin/data";
import "./projectOverview.css";
import axios from "axios";
import { fetchAuthSession, getCurrentUser } from "@aws-amplify/auth";

const ProjectOverview = () => {
  const [selectedProjectRows, setSelectedProjectRows] = useState([]);
  const [isOwnedToggled, setIsOwnedToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectRows, setProjectRows] = useState([]);
  const [ownedProjects, setOwnedProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const fetchProjects = async () => {
    setProjectsLoading(true);
    try {
      const user = await fetchAuthSession();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/project`,
        {
          headers: {
            Authorization: `Bearer ${user.tokens.idToken}`,
          },
        }
      );
      setProjectRows(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setProjectsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchOwnedProjects = async () => {
    try {
      const userEmail = (await getCurrentUser()).signInDetails.loginId;
      const userToken = (await fetchAuthSession()).tokens.idToken;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/user-project/${userEmail}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setOwnedProjects(response.data.map((project) => project.projectId));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchOwnedProjects();
  }, []);

  const toggleOwnedProjects = () => {
    setIsOwnedToggled(!isOwnedToggled);
  };

  const updatedColumns = projectColumnsFull.map((col) => {
    if (col.field === "projectName") {
      return {
        ...col,
        renderCell: (params) => {
          const isOwned = ownedProjects.includes(params.row.projectId);
          return (
            <a
              href={isOwned ? `/projects/${params.row.projectId}` : "#"}
              className={isOwned ? "owned-project" : "unowned-project"}
              style={{
                color: isOwned ? "blue" : "grey",
                pointerEvents: isOwned ? "auto" : "none",
              }}
            >
              {params.value}
            </a>
          );
        },
      };
    }
    if (col.field === "location") {
      return {
        ...col,
        renderCell: (params) => params.row.location?.address || "N/A",
      };
    }
    return col;
  });

  return (
    <div className="project__wrapper">
      <header className="project__header">
        <h1 className="project__title">Projects</h1>
        <h3 className="project__subtitle">View your available projects</h3>
        <div className="search-and-toggle">
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search projects..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-bar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <button onClick={toggleOwnedProjects} className="owned-button">
            {isOwnedToggled ? "All Projects" : "Owned"}
          </button>
        </div>
        <div className="project-table-container">
          <DataGrid
            getRowId={(row) => row.projectId}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selected = projectRows.filter((row) =>
                selectedIDs.has(row.projectId)
              );
              setSelectedProjectRows(selected);
              console.log(selected);
            }}
            selectionModel={selectedProjectRows.map((row) => row.projectId)}
            rows={
              isOwnedToggled
                ? projectRows.filter((row) =>
                    ownedProjects.includes(row.projectId)
                  )
                : projectRows
            }
            columns={updatedColumns}
            checkboxSelection
            className="custom-grid-projects"
            loading={projectsLoading}
          />
        </div>
      </header>
    </div>
  );
};

export default protectedRoute(ProjectOverview);
