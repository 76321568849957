import React, { useEffect, useState } from "react";
import "./admin.css";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import UserBoard from "./UserBoard/UserBoard";
import ProjectBoard from "./ProjectBoard/ProjectBoard";
import protectedRoute from "../../protectedRoute";
import { isUserAdmin, getIdToken } from "../util/auth";
import { Navigate } from "react-router-dom";

const Admin = () => {
  const [board, setBoard] = useState("user");

  const toggleButtonStyle = {
    "&.Mui-selected": {
      backgroundColor: "#ff7a7f",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#ff6166",
      },
    },
    width: "7rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleBoardChange = (event, newBoard) => {
    if (newBoard !== null) {
      setBoard(newBoard);
    }
  };
  const [isAdmin, setIsAdmin] = useState(null);
  useEffect(() => {
    async function checkAdminStatus() {
      const token = await getIdToken();
      if (token) {
        setIsAdmin(isUserAdmin(token));
      } else {
        setIsAdmin(false);
      }
    }

    checkAdminStatus();
  }, []);

  if (isAdmin === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isAdmin ? (
        <div className="admin__container">
          <header className="admin__header">
            <ToggleButtonGroup
              value={board}
              exclusive // Ensures only one button can be selected at a time
              onChange={handleBoardChange}
            >
              <ToggleButton value="user" sx={toggleButtonStyle}>
                User
              </ToggleButton>
              <ToggleButton value="project" sx={toggleButtonStyle}>
                Project
              </ToggleButton>
            </ToggleButtonGroup>
            <h1>
              {" "}
              {board === "user" ? "User Dashboard" : "Project Dashboard"}
            </h1>
          </header>

          {board === "user" ? <UserBoard /> : <ProjectBoard />}
        </div>
      ) : (
        <Navigate to="/projects" replace />
      )}
    </>
  );
};

export default protectedRoute(Admin);
