import React, { useRef, useEffect } from "react";
import { TextField } from "@mui/material";
import Verification from "./Verification";

const VerificationForm = ({
  length = 6,
  values = [],
  setValue,
  activeIndex,
  setActiveIndex,
  errorMessage = "",
  onPaste,
  onKeyDown,
  onChange,
}) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[activeIndex]) {
      inputRefs.current[activeIndex].focus();
    }
  }, [activeIndex]);

  return (
    <div className="verification__inputs">
      {Array.from({ length }, (_, index) => (
        <TextField
          key={index}
          inputRef={(el) => (inputRefs.current[index] = el)}
          variant="outlined"
          margin="dense"
          type="text"
          className="text-field"
          value={values[index] || ""}
          onChange={(event) => {
            const newValue = event.target.value.slice(-1); // Only take the last character
            onChange(newValue, index);
          }}
          onFocus={() => setActiveIndex(index)}
          onKeyDown={(event) => onKeyDown(event, index)}
          onPaste={onPaste}
          error={!!errorMessage}
        />
      ))}
    </div>
  );
};

export default VerificationForm;
