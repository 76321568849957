import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InputField from "../Inputs/InputField";
import VerificationForm from "../Verification/VerificationForm";
import "./forgotPassword.css";

const ForgotPassword = () => {
  const [verificationStage, setVerificationStage] = useState(1);
  const navigate = useNavigate();

  // Stage 1: Email submission form
  const handleEmailSubmit = async (data) => {
    try {
      await resetPassword({ username: data.email });
      setVerificationStage(2); // Move to Stage 2 after email submission
    } catch (error) {
      console.log(error);
    }
  };

  // Stage 2: Verification code form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      verificationCode: Array(6).fill(""), // Default to a 6-character verification code
    },
  });
  const [errorMessage, setErrorMessage] = useState("");
  const verificationCode = getValues("verificationCode");
  const [activeIndex, setActiveIndex] = useState(0);
  const dummyCode = "123456"; // Replace with the actual code for validation

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      if (verificationCode[index] === "" && index > 0) {
        setActiveIndex(index - 1);
      } else {
        setValue(`verificationCode.${index}`, "", { shouldValidate: true });
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData
      .getData("text/plain")
      .slice(0, verificationCode.length);

    const updatedCode = [...verificationCode];

    for (let i = 0; i < pastedData.length; i++) {
      if (activeIndex + i < updatedCode.length) {
        updatedCode[activeIndex + i] = pastedData[i];
      }
    }

    updatedCode.forEach((value, index) =>
      setValue(`verificationCode.${index}`, value, {
        shouldValidate: true,
      })
    );
    setActiveIndex(activeIndex + pastedData.length);
  };

  const handleChange = (newValue, index) => {
    setValue(`verificationCode.${index}`, newValue, {
      shouldValidate: true,
    });

    clearErrors("verificationCode");

    if (newValue && index < verificationCode.length - 1) {
      setActiveIndex(index + 1);
    }
  };

  const handleVerificationSubmit = async (data) => {
    const confirmationCode = data.verificationCode.join(""); // Combine the array into a single string
    try {
      await confirmResetPassword({
        username: data.email,
        confirmationCode,
        newPassword: data.password,
      });
      setVerificationStage(3); // Move to the next stage
      setErrorMessage("");
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message || "An error occurred. Please try again.");
    }
  };

  // Stage 3: Success message
  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="forgotPassword__container">
      {verificationStage === 1 && (
        <div className="forgotPassword__stage">
          <header className="forgotPassword__header">
            <div className="forgot-icon__container forgot-icon__container-grey">
              <MailOutlineIcon style={{ fontSize: "1.8rem" }} />
            </div>
            <h1 className="auth__header">Forgot Password?</h1>
            <h3 className="auth__subheader">
              No worries, we'll send you a verification code to reset your
              password.
            </h3>
          </header>
          <form
            className="forgotPassword__form"
            onSubmit={handleSubmit(handleEmailSubmit)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <InputField
              id="email"
              label="Email"
              type="text"
              register={register}
              validationRules={{
                required: "Email Name is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              }}
              errors={errors}
              errorMessageKey="email"
            />
            <button className="auth__button" type="submit">
              Send Code
            </button>
          </form>
        </div>
      )}

      {verificationStage === 2 && (
        <div className="forgotPassword__stage">
          <header className="forgotPassword__header">
            <div className="forgot-icon__container forgot-icon__container-grey">
              <LockResetIcon style={{ fontSize: "2rem" }} />
            </div>
            <h1 className="auth__header">Verification Code</h1>
            <h3 className="auth__subheader">
              Please enter the 6-digit verification code sent to your email.
            </h3>
          </header>

          <form
            className="forgotPassword__form-stage2"
            onSubmit={handleSubmit(handleVerificationSubmit)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <VerificationForm
              length={6}
              values={verificationCode}
              setValue={setValue}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              errorMessage={errorMessage}
              onPaste={handlePaste}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
            {errorMessage && (
              <span className="verification-error active">{errorMessage}</span>
            )}

            <InputField
              id="password"
              label="New Password"
              type="password"
              register={register}
              validationRules={{
                required: "Password is required",
              }}
              errors={errors}
              errorMessageKey="password"
            />

            <button className="auth__button" type="submit">
              Verify Code
            </button>
          </form>
        </div>
      )}

      {verificationStage === 3 && (
        <div className="forgotPassword__stage">
          <header className="forgotPassword__header">
            <div className="forgot-icon__container forgot-icon__container-green">
              <TaskAltIcon style={{ fontSize: "1.7rem" }} />
            </div>
            <h1 className="auth__header">Password Reset</h1>
            <h3 className="auth__subheader">
              Your password has been successfully reset.
            </h3>
            <button
              className="auth__button"
              type="submit"
              onClick={handleBackToLogin}
            >
              Back to Login
            </button>
          </header>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
