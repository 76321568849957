import React from "react";
import { TextField, MenuItem } from "@mui/material";

const SelectField = ({
  id,
  label,
  options,
  register,
  validationRules,
  errors,
  errorMessageKey,
  onChange, // Add the onChange prop to allow passing a custom handler
  defaultValue,
}) => (
  <div className="auth__input-container">
    <label className="auth__input-label" htmlFor={id}>
      {label}
    </label>
    <TextField
      id={id}
      select
      variant="outlined"
      {...register(id, validationRules)}
      error={!!errors[errorMessageKey]}
      helperText={errors[errorMessageKey]?.message}
      fullWidth
      size="small"
      onChange={onChange} // Pass the onChange prop to TextField
      sx={{ marginBottom: "1rem" }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  </div>
);

export default SelectField;
