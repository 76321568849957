import { Dialog } from "@mui/material";
import React from "react";
import "./modal.css";
const BaseModal = ({
  open,
  handleClose,
  title,
  subTitle,
  modalContainerStyles,
  content,
  maxWidth,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiDialog-paper": modalContainerStyles,
      }}
      fullWidth
      maxWidth={maxWidth}
      disableScrollLock
    >
      <div className="modal__container-content">
        <div className="modal__header">
          <h1>{title}</h1>
          <h2>{subTitle}</h2>
        </div>
        {content}
      </div>
    </Dialog>
  );
};

export default BaseModal;
