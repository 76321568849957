import { Element } from "react-scroll";
import ProfilePopup from "./ProfilePopup";
import useMediaQuery from "@mui/material/useMediaQuery";
import profile from "../../images/profile.jpg";
import cory from "../../images/cory-bergh.jpeg";
import andrea from "../../images/andrea-cherkas.jpeg";
import keith from "../../images/keith-steeves.jpeg";
import dejong from "../../images/Dejong_William.jpeg";
import cann from "../../images/GeoffreyCann Thumbnail master.png";
import martin from "../../images/Martin Kratz.jpeg";
import naan from "../../images/naan.png";
import mike from "../../images/mikeh.png";

import dejongcropped from "../../images/Dejong_William-cropped.jpeg";
import corycropped from "../../images/cory-bergh-cropped.jpeg";
import andreacropped from "../../images/andrea-cherkas-cropped.jpeg";
import keithcropped from "../../images/keith-steeves-cropped.jpeg";

import profilepic from "../../images/keith-steeves.jpeg";
import { useState, useRef, useEffect } from "react";
import { scroller } from "react-scroll";
import "./profiles.css";

import { useIsVisible } from "../../Hooks/IsVisible";
import ReactGA from "react-ga4";

const Profiles = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const startTime = Date.now();

  const imageSwapwidth = useMediaQuery("(max-width:19000px)");
  const imagepopupwidth = useMediaQuery("(max-width:1300px)");

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (isPopupOpen) {
      html.classList.add("lock-scroll");
    } else {
      html.classList.remove("lock-scroll");
    }
    return () => {
      html.classList.remove("lock-scroll");
    };
  }, [isPopupOpen]);

  const openPopup = (profile) => {
    setSelectedProfile(profile);
    setPopupOpen(true);
    document.body.style = "overflow-y: hidden;";
    if (profile.name)
      ReactGA.event({
        category: "PROFILE_CLICK", // Event category (should match what you defined in GA)
        action: "PROFILE_CLICK",
        label: profile.name,
        value: profile.event,
        // Event action
      });
  };

  const closePopup = () => {
    setPopupOpen(false);
    document.body.className = "";
  };

  const profiles = [
    {
      event: 0,
      name: "Cory Bergh",
      image: imageSwapwidth ? corycropped : corycropped,
      popupImage: corycropped,
      description: "Founder, President & CEO",
      education: "BComm, MBA, CFA",
      writeup:
        "With a wealth of experience spanning over 27 years, Cory Bergh has proven himself as a leader in the industry. Most recently serving as Vice President of Technology & Innovation at NAL Resources, an oil and gas company, Cory excelled as the Executive Leader and Sponsor for Digital Transformation and Carbon Footprint Reduction Strategies. His extensive background includes developing financial and management skills in venture capital, real estate, government, and insurance sectors.",
    },
    {
      event: 1,
      name: "Andrea Cherkas",
      image: imageSwapwidth ? andreacropped : andreacropped,
      popupImage: andreacropped,
      description: "Founder, Chief Marketing Officer",
      education: "BSc Chemical Engineering",
      writeup:
        "A seasoned executive and entrepreneur, Andrea Cherkas brings over 30 years of successful international experience to the table including leading B2B marketing, sales and business development with Fortune 500 companies to start-ups.  Throughout her career, she has worked across a diverse range of sectors, from petrochemical and energy distribution to technology and automotive. Andrea has also served as Managing Director, ClearPATH Business, engaging clients in oil and gas and other sectors in effectively driving growth and implementing Digital Transformation.",
    },
    {
      event: 2,
      name: "Keith Steeves",
      image: imageSwapwidth ? keithcropped : keithcropped,
      popupImage: keithcropped,
      description: "Founder, Chairman",
      education: "CPA-CGA, MBA, BBA, CIA",
      writeup:
        "Keith Steeves possesses 40 years of experience, culminating in his role as Chief Financial Officer (CFO) at NAL Resources, an oil and gas producer. With an extensive background in technology, distribution, venture capital, and energy sectors, Keith has held international CFO, VP Finance, and Director Information Management positions. His expertise lies in leading organizations through turnarounds, expansions, acquisitions, and attracting investment.",
    },
    {
      event: 3,
      name: "Geoffrey Cann",
      image: imageSwapwidth ? cann : cann,
      popupImage: cann,
      description: "Advisory Board Member",
      education: "",
      writeup:
        "Drawing from over 30 years of experience, including his tenure as the former Partner, Technology Practice at Deloitte Canada, Geoffrey Cann is a highly regarded professional within the energy sector. His international exposure and passion for innovation have shaped his career as an author, educator, and inspiring speaker. Geoffrey's commitment to leveraging digital innovations aligns with his belief in the oil and gas industry's importance during our transition to new fuels.",
    },
    {
      event: 4,
      name: "William DeJong",
      image: imageSwapwidth ? dejongcropped : dejongcropped,
      popupImage: dejongcropped,
      description: "Advisory Board Member",
      education: "",
      writeup:
        "With a distinguished career spanning over three decades, Bill DeJong is an esteemed expert in securities and corporate finance legal matters. He has contributed to numerous public, corporate, and community boards, and his former partnership at Dentons Canada LLP is a testament to his legal prowess. Recognized for his achievements, Bill has been awarded the Alberta Centennial Medal and earned the distinction of Queen Council.",
    },
    {
      event: 5,
      name: "Mike Heffring",
      image: imageSwapwidth ? mike : mike,
      popupImage: mike,
      description: "Advisory Board Member",
      education: "",
      writeup:
        "Bringing four decades of experience in the realms of information economy, marketing, and strategy, Mike Heffring's expertise is unparalleled. As a retired professor of marketing at the University of Calgary, Haskayne School of Business, Mike has made significant contributions to academia. Additionally, his executive roles and founding of Environics Research West have positioned him as a sought-after investor and advisor for technology companies, emphasizing customer engagement strategies driven by innovation and data.",
    },
    {
      event: 6,
      name: "Martin Kratz",
      image: imageSwapwidth ? martin : martin,
      popupImage: martin,
      description: "Advisory Board Member",
      education: "",
      writeup:
        "As Co-director of Osgoode Hall Law School's Intellectual Property LLM program and a cybersecurity instructor at Concordia University, Martin Kratz is a recognized authority in the field of law. Having dedicated decades to his career as a senior technology and intellectual property lawyer at Bennett Jones, Martin's expertise is widely respected. His continued involvement in legal education showcases his commitment to shaping the next generation of legal professionals.",
    },
  ];

  const Founders = profiles.slice(0, 3);
  const Advisory = profiles.slice(3);

  return (
    <Element
      id="team"
      name="team"
      className="founders-container section-padding"
    >
      <div className="profile-header-container">
        <div className="heading-primary">Meet our team</div>
        <div className="profile-text-header">
          With over 100 years of combined experience, our mission and dedication
          to quality is in good hands.
        </div>
      </div>
      <div className="profile-section-wrapper">
        <h3 className="profile-section-heading">OUR FOUNDERS</h3>
        <div className="profiles-wrapper founders-wrapper">
          {Founders.map((profile, index) => (
            <div
              className={`profile-card`}
              key={index}
              onClick={() => openPopup(profile)}
            >
              <div className="profile-img-container">
                <img src={profile.image} alt={profile.name} />
              </div>
              <div className="profile-name-container">
                <div className="profile-title-name">{profile.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className='profile-section-wrapper'>
        <h3 className='profile-section-heading'>ADVISORY BOARD</h3>
        <div className='profiles-wrapper advisory-wrapper'>

          {Advisory.map((profile, index) => (
            <div className={`profile-card`} key={index} onClick={() => openPopup(profile)}>
              <div className='profile-img-container'>
                <img src={profile.image} alt={profile.name} />
              </div>
              <div className='profile-name-container'>
                <div className='profile-title-name'>{profile.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      {selectedProfile && (
        <ProfilePopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          profile={selectedProfile}
        />
      )}
    </Element>
  );
};

export default Profiles;
