import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const InputField = ({
  id,
  label,
  type = "text",
  register,
  validationRules,
  errors,
  errorMessageKey,
  size = "small",
  rows,
  onChange, // Add onChange prop
  value, // Add value prop for controlled inputs
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const getType = () => {
    if (type === "password") {
      if (showPassword) {
        return "text";
      }
    }
    return type;
  };
  return (
    <div className="auth__input-container">
      <label className="auth__input-label" htmlFor={id}>
        {label}
      </label>
      <TextField
        id={id}
        type={getType()}
        {...register(id, validationRules)}
        size={size}
        error={!!errors[errorMessageKey]}
        rows={rows}
        multiline={!!rows} // Ensure multiline is only enabled if rows are provided
        onChange={onChange} // Pass onChange to TextField
        value={value} // Pass value to TextField
        fullWidth
        variant="outlined"
        InputProps={
          type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
      <div className="error__container">
        {errors[errorMessageKey] && (
          <span>{errors[errorMessageKey].message}</span>
        )}
      </div>
    </div>
  );
};

export default InputField;
