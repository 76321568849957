import { jwtDecode } from "jwt-decode";
import { fetchAuthSession } from "@aws-amplify/auth";

export function isUserAdmin(idToken) {
  const env = process.env.REACT_APP_ENV === "prod" ? "-prod" : "";
  const groups = idToken.payload["cognito:groups"] || [];
  return groups.includes("AdminGroup" + env);
}

export async function getIdToken() {
  try {
    const session = await fetchAuthSession();
    return session.tokens.idToken;
  } catch (error) {
    console.error("Error retrieving ID token:", error);
    return null;
  }
}
