import { Element } from "react-scroll";
import "./home.css";

const Home = () => {
  return (
    <Element id="home" name="home" className="home-container">
      <video
        src={process.env.REACT_APP_S3_VIDEOS + "/sunRiseLoop.mp4"}
        autoPlay
        loop
        muted
        playsInline
        className="video-hero"
      />

      <div className="home-text-container">
        <p className="text-primary">
          Emerging leaders in lowering greenhouse gas emissions by decarbonizing
          oil and gas production.
        </p>
      </div>
    </Element>
  );
};

export default Home;
