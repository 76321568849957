import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { projectColumns } from "../data";
import { fetchAuthSession } from "@aws-amplify/auth";

const EditUser = ({ projects, userData, handleClose }) => {
  const [selectedProjectRows, setSelectedProjectRows] = useState([]);
  const [preSelectedProjects, setPreSelectedProjects] = useState([]);

  useEffect(() => {
    if (userData) {
      const projectIds = userData.selectedProjects.map((p) => p.projectId);
      setSelectedProjectRows(projectIds);
      setPreSelectedProjects(projectIds); // Store original selection for comparison
    }
  }, [userData]);

  const handleUpdate = async () => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken; // Ensure token is available
      if (!idToken) {
        console.error("Failed to retrieve ID token");
        return;
      }

      const email = userData.email;
      const authHeader = {
        headers: { Authorization: `Bearer ${idToken}` },
      };

      // Find projects to delete and add
      const projectsToDelete = preSelectedProjects.filter(
        (id) => !selectedProjectRows.includes(id)
      );
      const projectsToAdd = selectedProjectRows.filter(
        (id) => !preSelectedProjects.includes(id)
      );

      console.log("Projects to delete:", projectsToDelete);
      console.log("Projects to add:", projectsToAdd);

      // Perform DELETE requests first
      await Promise.all(
        projectsToDelete.map((projectId) =>
          axios.delete(`${process.env.REACT_APP_BACKEND_API}/user-project`, {
            data: { email, projectID: projectId }, // DELETE body must be inside config.data
            ...authHeader,
          })
        )
      );

      // Perform POST requests for new selections
      await Promise.all(
        projectsToAdd.map((projectId) =>
          axios.post(
            `${process.env.REACT_APP_BACKEND_API}/user-project`,
            { email, projectID: projectId },
            authHeader
          )
        )
      );

      console.log("Update successful");
      handleClose(); // Close modal after update
      alert("Updating user successful");
    } catch (error) {
      console.error("Error updating projects");
      alert("Updating user failed");
    }
  };

  return (
    <div className="editUser__container">
      <DataGrid
        sx={{ marginLeft: "auto", width: "100%" }}
        getRowId={(row) => row.projectId}
        className="custom-grid"
        rows={projects}
        columns={projectColumns}
        checkboxSelection
        rowSelectionModel={selectedProjectRows}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedProjectRows(newSelection);
        }}
      />

      <div className="modal__actionBtns">
        <button className="modal__btn" onClick={handleClose}>
          Cancel
        </button>
        <button className="modal__btn" onClick={handleUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

export default EditUser;
